import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useGeneralStore = defineStore('generalStore', () => {
    const activeStory = ref<string>('');
    const isGlobalModalOpen = ref<boolean>(false);
    const globalModalKey = ref<number>(0);

    const closeGlobalModal = () => {
        isGlobalModalOpen.value = false;
    }
  
    return { activeStory, globalModalKey, isGlobalModalOpen, closeGlobalModal }
});