<script lang="ts" setup>
import { computed, defineExpose, defineProps, onMounted, ref } from "vue";
import { DateTime } from 'luxon';
import algoliasearch from 'algoliasearch/lite';

import EventList from '@/components/EventList.vue';


const props = defineProps({
    relatedSlug: {
      type: String,
      required: true
    }
});

const searchClient = algoliasearch("9DTOW0B5ES", "d792c1658136ad0f4b476269988b8e21");
const index = searchClient.initIndex('events');

const hasEvents = computed(() => eventList.value.length > 0);
defineExpose({ hasEvents });

const loadingData = ref<boolean>(false);

const eventList = ref<any[]>([]);


const getEventsData = async () => {
  const filters = `start > ${DateTime.now().toFormat('yyyyMMdd')}`;

  const response = await index.search(props.relatedSlug,
      {
        filters: filters,
        page: 0,
        hitsPerPage: 10,
        restrictSearchableAttributes: ['name'],  // Restrict search to the 'name' attribute
      }
  );
  eventList.value.push(...response.hits);
}

onMounted(async () => {
  loadingData.value = true;
  await getEventsData();
  loadingData.value = false;
});

</script>

<template>
    <EventList :events="eventList" :show-header="false" />
</template>
 
<style>

</style>