import axios from "axios";

const apiUrl = 'https://us-central1-watch-comedy-live.cloudfunctions.net/watchcomedy_live_api/v1';

export const getYoutubeVideosByChannel = async function (slug: string) {
    //const axiosResponse = await axios.get(`${apiUrl}/youtube/channel/${slug}?numberOfResults=${numberOfResults || 10}`);
    const axiosResponse = await axios.get(`https://latestfromyoutube-6dlfbaafzq-uc.a.run.app/?channelId=${slug}`);
    return axiosResponse.data;
}

const ipifyData = async function () {
    try {
        const response = await axios.get('https://api.ipify.org?format=json');
        return response.data;
    }
    catch (err) {
        return null;
    }
}

// export const newsLetterSignup = async function (email: string) {
//     const geoData:any = await cloudFlairData() ?? await ipInfoData();
//     const ip: string = geoData ? geoData.ip : null;
//     const response = await axios.post(`${apiUrl}/newsletterSignup`, {email, ip, data: geoData});
//     return response.data;
// }

export const submitContactRequest = async function (email: string, firstName?: string, lastName?: string, message?: string) {
    const response = await axios.post(`https://contactrequest-6dlfbaafzq-uc.a.run.app/contactRequest`, { email, firstName, lastName, message });
    return response.data;
}