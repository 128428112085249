<script lang="ts" setup>
import { defineProps, onMounted, ref } from "vue";
import { useStoryblokApi } from "@storyblok/vue";

import Modal from "@/components/Modal.vue";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

import 'swiper/css';

const props = defineProps({
    contentIds: {
      type: String,
      required: false
    },
    content: {
      type: Array as () => any[],
      required: false
    },
    contentType: {
      type: String,
      required: false,
      default: 'comics'
    }
});

const storyblokApi = useStoryblokApi();

const loadingData = ref<boolean>(false);
const showModal = ref<boolean>(false);
const activeSlug = ref<string>('');
const modalKey = ref<number>(0);
const swiperRef = ref<any>(null);
const contentList = ref<any[]>([]);

const onSwiperInstance = (v: any) => swiperRef.value = v;

const getContentList = async () => {
  loadingData.value = true;

  if(props.content) {
    contentList.value = props.content;
    loadingData.value = false;
    return;
  }

  try {
    const { data } = await storyblokApi.get(
      'cdn/stories',
      {
        'per_page': 10,
        'sort_by': 'content.date:desc',
        'by_uuids': props.contentIds as string,
        version: "published"
      }
    );

    if (data) {
      contentList.value = data.stories;
    }

  } catch (error) {
    console.log(error);
  }
  loadingData.value = false;
};

const getImageUrl = (item: any) => {
  if(!item) return '';

  if(item.video_id) {
    return `https://img.youtube.com/vi/${item.video_id}/hqdefault.jpg`;
  } 
  if(item.images && item.images[0]) {
    return `${item.images[0].filename}/m/200x100/smart/filters:format(webp)`;
  } 
  else if(item.image && item.image.filename) {
    return `${item.image.filename}/m/200x100/smart/filters:format(webp)`;
  } 
  else if(item.logo && item.logo.filename) {
    return `${item.logo.filename}/m/200x100/smart/filters:format(webp)`;
  }
  return '';
};

const openStory = (slug: string) => {
  activeSlug.value = slug;
  showModal.value = true;
  modalKey.value++;
};

onMounted(async() => {
    await getContentList();
    setTimeout(() => {
        swiperRef.value.update();
    }, 1);
});

</script>

<template>
    <div class="pl-3">
      <swiper 
        ref="swiperRef2" 
        @swiper="onSwiperInstance" 
        :slides-per-view="3" 
        :space-between="25"
        navigation>
            <swiper-slide v-for="item in contentList" :key="item.id" :swiperRef="swiperRef">
                <div @click="openStory(item.full_slug)">
                  <Card
                    class="swiper-slide"
                    :condensed="true"
                    @click="openStory(item.full_slug)"
                    :slug="item.full_slug"
                    :image-url="getImageUrl(item.content)"
                    :title="item.content.title || item.content.name" />  
                </div>
            </swiper-slide>
        </swiper>

        <Modal v-if="showModal" :key="`relatedContent${modalKey}`" @close-modal="showModal = false;" :content-slug="activeSlug" />
    </div>
</template>
 
<style>

</style>