import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/news',
  },
  {
    name: 'News',
    path: '/news',
    component: () => import('@/views/NewsView.vue'),
    meta: {
      title: 'News',
      metaTags: [
        {
          name: 'description',
          content: 'The home page of our example app.'
        },
        {
          property: 'og:description',
          content: 'The home page of our example app.'
        }
      ]
    }
  },
  {
    name: 'Events',
    path: '/events',
    component: () => import ('@/views/EventsView.vue')
  },
  {
    name: 'Comics',
    path: '/comics',
    component: () => import ('@/views/ComicsView.vue')
  },
  {
    name: 'Podcasts',
    path: '/podcasts',
    component: () => import ('@/views/PodcastsView.vue'),
  },
  {
    name: 'Specials',
    path: '/specials',
    component: () => import ('@/views/SpecialsView.vue')
  },
  {
    name: 'About',
    path: '/about',
    component: () => import ('@/views/AboutView.vue')
  },
  {
    name: 'Profiles',
    path: '/profiles',
    component: () => import ('@/views/ProfilesView.vue')
  },
  {
    name: 'Contact',
    path: '/contact',
    component: () => import ('@/views/ContactView.vue')
  },
  {
    name: 'Terms',
    path: '/terms',
    component: () => import ('@/views/TermsView.vue')
  },
  {
    name: 'Privacy',
    path: '/privacy',
    component: () => import ('@/views/PrivacyView.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

export default router
