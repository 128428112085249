<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useStoryblokApi } from "@storyblok/vue";
import {
    IonContent,
    IonItem,
    IonLabel,
    IonList,
    IonMenu,
    IonMenuToggle,
    IonIcon,
    IonGrid,
    IonRow,
    IonCol
} from '@ionic/vue';

import {
    logoInstagram,
    logoTiktok,
    logoTwitter,
    logoYoutube
} from 'ionicons/icons';

const storyblokApi = useStoryblokApi();

const selectedIndex = ref<number>(0);
const siteConfig = ref<any>({});
const loadingConfig = ref<boolean>(false);

const socialLinks = computed(() => {
    return [
        {
            title: "TikTok",
            icon: logoTiktok,
            url: siteConfig.value.tiktok_url ? siteConfig.value.tiktok_url.url : ""
        },
        {
            title: "Instagram",
            icon: logoInstagram,
            url: siteConfig.value.instagram_url ? siteConfig.value.instagram_url.url : ""
        },
        {
            title: "X",
            icon: logoTwitter,
            url: siteConfig.value.x_url ? siteConfig.value.x_url.url : ""
        },
        {
            title: "YouTube",
            icon: logoYoutube,
            url: siteConfig.value.youtube_url ? siteConfig.value.youtube_url.url : ""
        }
    ]
})

onMounted(async () => {
    loadingConfig.value = true;
    try {
        const { data } = await storyblokApi.get(
            "cdn/stories/app-config-jokewrld",
            { version: "published" } // API Options
        );
        if (data) {
            siteConfig.value = data.story.content;
        }
    } catch (error) {
        console.log(error);
    }
    loadingConfig.value = false;
});

</script>

<template>
    <ion-menu side="end" content-id="main-content" type="overlay" v-if="!loadingConfig">
        <ion-content>
            <ion-list id="inbox-list" class="!pt-8">
                <ion-menu-toggle 
                    :auto-hide="false" 
                    v-for="(link, linkIndex) in siteConfig.global_navigation" 
                    :key="linkIndex">
                    <ion-item 
                        @click="selectedIndex = linkIndex" 
                        router-direction="root" 
                        :router-link="link.link.url"
                        :detail="true" 
                        class="flex items-center cursor-pointer"
                        :class="{ selected: selectedIndex === linkIndex }">
                        <fa icon="align-right"></fa>
                        <ion-label>{{ link.link.title }}</ion-label>
                    </ion-item>
                </ion-menu-toggle>
            </ion-list>

            <ion-grid>
                <ion-row>
                    <ion-col 
                        size="3"
                        v-for="(socialLink, socialLinkIndex) in socialLinks"
                        :key="socialLinkIndex">
                        <ion-item 
                            class="cursor-pointer"
                            lines="none" 
                            :detail="false" 
                            :href="socialLink.url"
                            target="_blank">
                            <ion-icon :icon="socialLink.icon"></ion-icon>
                        </ion-item>
                    </ion-col>
                </ion-row>
            </ion-grid>

        </ion-content>
    </ion-menu>
</template>

<style lang="postcss" scoped>

</style>