import { format } from "date-fns";
import { renderRichText } from '@storyblok/vue';
import { DateTime } from 'luxon';

export const formatDate = (date: string) => {
    return format(new Date(date), "MMMM dd, yyyy");
};

export const formatDateEvent = (date: string) => {
    return DateTime.fromFormat(date.toString(), 'yyyyMMdd').toFormat('MMM dd, yyyy')
};

export const resolveHtml = (html: any) => {
    return renderRichText(html)
}
