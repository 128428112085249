import { ref } from "vue";
import {DateTime} from "luxon";
const bus = ref(new Map());

export default function useEventsBus(){

  function emit(event: string, args: unknown[] = []) {
    bus.value.set(event, [...args, DateTime.now()]);
  }

  return {
    emit,
    bus
  }
}