import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import { StoryblokVue, apiPlugin } from "@storyblok/vue";
import { createPinia } from 'pinia';
import { IonicVue } from '@ionic/vue';
import { createHead } from '@unhead/vue';
import VueGtag from "vue-gtag";

import Card from "@/components/Card.vue";
import GlobalHeader from "@/components/GlobalHeader.vue";
import GlobalFooter from "@/components/GlobalFooter.vue";

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Theme variables */
import './theme/variables.css';

const head = createHead()

const app = createApp(App)
  .use(IonicVue, {
    mode: 'ios'
  })
  .use(router)
  .use(head)
  .use(createPinia())
  .use(VueGtag, {
    config: { id: process.env.VUE_APP_GOOGLE_TAG_ID }
  })
  .component('Card', Card)
  .component('GlobalHeader', GlobalHeader)
  .component('GlobalFooter', GlobalFooter)
  .use(StoryblokVue, {
    accessToken: 'MkBM7rEhmtpM5quuwyGjWAtt',
    apiOptions: { region: 'us' },
    use: [apiPlugin],
  });
  
router.isReady().then(() => {
  app.mount('#app');
});