<script lang="ts" setup>
import { computed, defineExpose, defineProps, onMounted, ref } from "vue";
import { useStoryblokApi } from "@storyblok/vue";

import Modal from "@/components/Modal.vue";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

import 'swiper/css';

const props = defineProps({
    itemId: {
      type: String,
      required: true
    },
    contentType: {
      type: String,
      required: false,
      default: 'comic'
    }
});

interface Video {
  id: string;
  full_slug: string;
  content: {
    title: string;
    name: string;
    video_id: string;
    images: {
      filename: string;
    }[];
  };
}

const emit = defineEmits(['videoOpened']);

const storyblokApi = useStoryblokApi();

const loadingData = ref<boolean>(false);
const showModal = ref<boolean>(false);
const activeSlug = ref<string>('');
const modalKey = ref<number>(0);
const swiperRef = ref<any>(null);
const videoList = ref<Video[]>([]);
const hasVideos = computed(() => videoList.value.length > 0);

defineExpose({ hasVideos })

const onSwiperInstance = (v: any) => {
    swiperRef.value = v;
};

const getVideoList = async () => {
  loadingData.value = true;
  try {
    const params:any = {
      'per_page': 10,
      'sort_by': 'content.date:desc',
      'starts_with': "videos/",
      version: "published",
    };

    switch (props.contentType) {
      case 'comic':
        params.filter_query = {
          related_comics: {
            any_in_array: props.itemId
          }
        };
        break;
      case 'podcast':
        params.filter_query = {
          related_podcasts: {
            any_in_array: props.itemId
          }
        };
        break;
      case 'comedy-special':
        params.filter_query = {
          related_comedy_specials: {
            any_in_array: props.itemId
          }
        };
        break;
      default:
        params.filter_query = {
          related_comics: {
            any_in_array: props.itemId
          }
        };
        break;
    }
    
    const { data } = await storyblokApi.get(
      'cdn/stories',
      params
    );

    if (data) {
      videoList.value = data.stories;
    }

  } catch (error) {
    console.log(error);
  }

  loadingData.value = false;
};

const getRelatedVideos = async () => {
  loadingData.value = true;

  if(props.itemId === '') return;
  try {
    const { data } = await storyblokApi.get(
      'cdn/stories',
      {
        'per_page': 10,
        'sort_by': 'content.date:desc',
        'by_uuids': props.itemId as string,
        version: "published"
      }
    );

    if (data) {
      videoList.value = data.stories;
    }

  } catch (error) {
    console.log(error);
  }
  loadingData.value = false;
};

const openStory = (slug: string) => {
  activeSlug.value = slug;
  showModal.value = true;
  modalKey.value++;
  emit('videoOpened');
};

onMounted(async() => {
  props.contentType === 'video' ? await getRelatedVideos() : await getVideoList();
  setTimeout(() => {
      swiperRef.value.update();
  }, 1);
});

</script>

<template>
    <div class="pl-3">
      <swiper 
        ref="swiperRef2" 
        @swiper="onSwiperInstance" 
        :slides-per-view="3" 
        :space-between="25"
        navigation>
          <swiper-slide v-for="item in videoList" :key="item.id" :swiperRef="swiperRef">
            <Card
              class="swiper-slide"
              :condensed="true"
              @click="openStory(item.full_slug)"
              :slug="item.full_slug"
              :image-url="item.content.video_id ? `https://i.ytimg.com/vi/${item.content.video_id}/hqdefault.jpg` : `${item.content.images[0].filename}/m/200x100/smart/filters:format(webp)`"
              :title="item.content.title || item.content.name" />        
          </swiper-slide>
        </swiper>

        <Modal v-if="showModal" :key="modalKey" @close-modal="showModal = false;" :content-slug="activeSlug" />
    </div>
</template>
 
<style>
</style>