<script setup lang="ts">
import { defineProps, onBeforeMount, ref } from 'vue';
import { formatDate } from '@/utils/helper';
import { Video } from '@/utils/types';
import { getYoutubeVideosByChannel } from "@/services/api.services";

import YoutubeEmbed from '@/components/YoutubeEmbed.vue';

import { closeOutline } from 'ionicons/icons';

import {
  IonButtons,
  IonButton,
  IonModal,
  IonHeader,
  IonContent,
  IonToolbar,
  IonText,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonSkeletonText
} from '@ionic/vue';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

import 'swiper/css';

const props = defineProps({ channelId: String });

const showModal = ref<boolean>(false);
const activeVideo = ref<Video>();
const modalKey = ref<number>(0);
const swiperRef = ref<any>(null);
const loadingData = ref<boolean>(false);
const videoList = ref<Video[]>([]);

const onSwiperInstance = (v: any) => swiperRef.value = v;

const getVideoList = async () => {
  if(props.channelId) {
    loadingData.value = true;
    try {
      const episodes = await getYoutubeVideosByChannel(props.channelId);

      episodes.forEach((item: any) => {
        
        videoList.value.push({
          id: item.id,
          full_slug: `${props.channelId}-${item.videoId}`,
          content: {
            title: item.title,
            video_id: item.videoId,
            description: item.description,
            date: item.publishedAt,
            thumbnail_url: item.thumbnail,
          }
        });
      });
    } catch(err) {
      console.log(err);
    }
   
    loadingData.value = false;
  }
};

const openStory = (video: Video) => {
  activeVideo.value = video;
  showModal.value = true;
  modalKey.value++;
};

const cancel = () => {  
  showModal.value = false;
  modalKey.value++;
};

onBeforeMount(async () => {
  loadingData.value = true;
  await getVideoList();
  loadingData.value = false;
});
</script>

<template>
  <ion-grid class="mb-5" v-if="loadingData">
    <ion-row>
      <ion-col v-for="n in 3" :key="n">
        <p><ion-skeleton-text animated class="w-full h-14 mb-2"></ion-skeleton-text></p>
        <p>
          <ion-skeleton-text animated class="w-2/3 h-6"></ion-skeleton-text>
        </p>
      </ion-col>
    </ion-row>
  </ion-grid>
  <div v-else>
    <swiper 
      ref="swiperRef2" 
      @swiper="onSwiperInstance"
      :slides-per-view="3" 
      :space-between="25"
      navigation>
      <swiper-slide v-for="item in videoList" :key="item.id" :swiperRef="swiperRef">
        <Card
          class="swiper-slide"
          :condensed="true"
          @click="openStory(item)"
          :slug="item.full_slug"
          :image-url="item.content.thumbnail_url"
          :title="item.content.title" />        
      </swiper-slide>
    </swiper>


    <ion-modal :is-open="showModal">
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click="cancel()">
              <ion-icon :icon="closeOutline"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>

      <ion-content>
        <YoutubeEmbed
          v-if="activeVideo?.id" 
          :video-id="activeVideo?.id ? activeVideo.id : ''" />
      
        <div class="ion-padding">
          <ion-text>
            <h1 class="mb-2 !text-2xl" v-html="activeVideo?.content.title"></h1>
          </ion-text>

          <ion-text v-if="activeVideo?.content.date">
            <p class="font-semibold mb-1 text-xs text-gray-400">{{ activeVideo.content.date }}</p>
          </ion-text>

          <ion-text>
            <div v-html="activeVideo?.content.description"></div>
          </ion-text>
        </div>
      </ion-content>
    </ion-modal>
  </div>
</template>

<style scoped>

</style>
