<script setup lang="ts">
import { IonButtons, IonHeader, IonIcon, IonButton, IonMenuButton, IonToolbar } from '@ionic/vue';
import { menuController } from '@ionic/core';
import { menuOutline, personCircleOutline } from 'ionicons/icons';

defineExpose({ menuController, personCircleOutline });

import GlobalSearch from '@/components/GlobalSearch.vue';

const openNav = async (id: string) => { 
  // await menuController.enable(true, id);
  await menuController.open(id);
};
</script>

<template>
  
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start" class="!w-fit">
        <ion-button class="!h-auto"  @click="$router.push({ name: 'News' })" title="Joke WRLD News">
          <img 
            class="mt-[7px] w-8 h-8 top-0" 
            title="Joke WRLD"
            src="https://a-us.storyblok.com/f/1002656/1167x1175/d18c32c217/jokewlrd-logo.png" />
        </ion-button>
      </ion-buttons>

      <GlobalSearch />

      <ion-buttons slot="end" class="!w-fit">
        <ion-menu-button class="!h-12" @click.prevent="openNav('end')">
          <ion-icon class="!mt-1" size="large" :icon="menuOutline"></ion-icon>
        </ion-menu-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  
</template>
