<script setup lang="ts">
import { ref } from "vue";
import { IonContent, IonModal, IonItem, IonLabel, IonList, IonListHeader, IonSearchbar, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon } from '@ionic/vue';
import { closeOutline } from 'ionicons/icons';
import algoliasearch from 'algoliasearch/lite';
import { useGeneralStore } from '@/stores/general';
import { storeToRefs } from 'pinia';
import { formatDateEvent } from '@/utils/helper';


const { activeStory, isGlobalModalOpen } = storeToRefs(useGeneralStore());

const searchClient = algoliasearch("9DTOW0B5ES", "d792c1658136ad0f4b476269988b8e21");
const index = searchClient.initIndex('all-content');
const indexEvents = searchClient.initIndex('events');

const results = ref<any[]>([]);
const comicList = ref<any[]>([]);
const videoList = ref<any[]>([]);
const podcastList = ref<any[]>([]);
const specialList = ref<any[]>([]);
const eventList = ref<any[]>([]);
const venueList = ref<any[]>([]);
const searchQuery = ref<string>('');
const showResultsModal = ref<boolean>(false);

const search = async () => {
  const query = searchQuery.value.toLowerCase();

  if (query.length > 2) {
    index.search(query, { hitsPerPage: 100, attributesToRetrieve: ['title', 'name', 'type', 'slug'] })
      .then((response: any) => {
        results.value = response.hits;

        comicList.value = response.hits.filter((hit: any) => hit.type === 'comic');
        videoList.value = response.hits.filter((hit: any) => hit.type === 'video');
        podcastList.value = response.hits.filter((hit: any) => hit.type === 'podcast');
        specialList.value = response.hits.filter((hit: any) => hit.type === 'comedy-special');
        venueList.value = response.hits.filter((hit: any) => hit.type === 'venue');
        
        if(results.value.length > 0) showResultsModal.value = true;
      }
    );

    indexEvents.search(query, { attributesToRetrieve: ['name', 'start', 'venue', 'link', 'thumbnail'] })
      .then((response: any) => {
        eventList.value = response.hits;
        
        if(results.value.length > 0) showResultsModal.value = true;
      }
    );

  }
};

const executeSearch = async (e: any) => {
  searchQuery.value = e.target.value as string;
  await search();
};

const openStory = (slug: string) => {
  isGlobalModalOpen.value = true;
  activeStory.value = slug;
};

const openEvent = (url: string) => {
  window.open(url, '_blank');
};

const clearSearch = () => {
  searchQuery.value = '';
  results.value = [];
  eventList.value = [];
  comicList.value = [];
  videoList.value = [];
  podcastList.value = [];
  specialList.value = [];
};

const closeModal = () => {
  showResultsModal.value = false;
  clearSearch();
};
</script>

<template>
  <div class="relative">
    <ion-searchbar 
      placeholder="Search Comics, Videos, Podcasts, Events..." 
      :debounce="500" 
      :value="searchQuery"
      class="!py-0 !px-3"
      @ionInput="executeSearch($event)"
      @ionClear="clearSearch()">
    </ion-searchbar>

    <ion-modal class="absolute top-0 left-0 w-full" :is-open="showResultsModal">
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click="closeModal()">
              <ion-icon :icon="closeOutline"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>

      <ion-searchbar 
        placeholder="News, Videos, Comics, Podcasts..." 
        :debounce="500" 
        :value="searchQuery"
        @ionInput="executeSearch($event)"
        @ionClear="clearSearch()">
      </ion-searchbar>

      <ion-content>
        <ion-list>
          <ion-list-header v-if="podcastList && podcastList.length" class="header-title">Podcasts</ion-list-header>
          <ion-item v-for="item in podcastList" :key="item.objectID" @click="openStory(item.slug)">
            <ion-label>{{ item.title }}</ion-label>
          </ion-item>

          <ion-list-header v-if="comicList && comicList.length" class="header-title">Comics</ion-list-header>
          <ion-item v-for="item in comicList" :key="item.objectID" @click="openStory(item.slug)">
            <ion-label>{{ item.title }}</ion-label>
          </ion-item>

          <ion-list-header v-if="venueList && venueList.length" class="header-title">Venues</ion-list-header>
          <ion-item v-for="item in venueList" :key="item.objectID" @click="openStory(item.slug)">
            <ion-label>{{ item.title }}</ion-label>
          </ion-item>

          <ion-list-header v-if="specialList && specialList.length" class="header-title">Comedy Specials</ion-list-header>
          <ion-item v-for="item in specialList" :key="item.objectID" @click="openStory(item.slug)">
            <ion-label>{{ item.title }}</ion-label>
          </ion-item>

          <ion-list-header v-if="eventList && eventList.length" class="header-title">Events</ion-list-header>
          <ion-item v-for="item in eventList" :key="item.objectID" @click="openEvent(item.link)">
            <ion-label>
              <div>{{ item.name }}</div>
              <p>{{ formatDateEvent(item.start) }} - {{ item.venue.address.city }}</p>
            </ion-label>
          </ion-item>

          <ion-list-header v-if="videoList && videoList.length" class="header-title">Videos</ion-list-header>
          <ion-item v-for="item in videoList" :key="item.objectID" @click="openStory(item.slug)">
            <ion-label>{{ item.title }}</ion-label>
          </ion-item>
        </ion-list>
      </ion-content>
      
    </ion-modal>
  </div>
</template>

<style lang="postcss" scoped>
ion-searchbar.searchinput {
  @apply !text-xs;

  input.searchbar-input.sc-ion-searchbar-ios {
    @apply !text-xs;

    &::placeholder {
      @apply !text-xs;
    }
  }
}

.header-title {
  @apply !font-semibold !text-xs uppercase !text-gray-400 !mt-1;
}
</style>