<script setup lang="ts">
import { defineProps, ref } from 'vue';

import { IonIcon } from '@ionic/vue';

  defineProps({ 
    videoId: String,
    videoTitle: String,
    videoWidth: {
      type: String,
      default: '100%'
    },
    videoHeight: {
      type: String,
      default: '300px'
    }
  });
  const ytPlayer = ref();
  const stopVideoPlayback = () => {
    const iframe = ytPlayer.value;
    iframe.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
  };
  defineExpose({ stopVideoPlayback });
</script>

<template>
  <div>
    <iframe
      ref="ytPlayer"
      v-if="videoId"
      :width="videoWidth" 
      :height="videoHeight" 
      :src="`https://www.youtube.com/embed/${videoId}?autoplay=1&enablejsapi=1&utm_source=app`"
      :title="videoTitle || ''"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
      allowfullscreen>
    </iframe>
    <button @click="stopVideoPlayback">stop</button>
  </div>
</template>

<style lang="postcss" scoped>
.youtube-image {
  @apply w-full relative bg-cover bg-no-repeat flex items-center justify-center;
  
  .circle {
    @apply rounded-full bg-white w-12 h-12 shadow-lg flex items-center justify-center;

    i {
      @apply text-red-500 text-6xl;
    }
  }

  ion-icon {
    @apply text-red-500;
  }
  
}
</style>
   
  