<script setup lang="ts">
import { IonTabs, IonRouterOutlet, IonTabBar, IonTabButton, IonLabel, IonIcon } from '@ionic/vue';
import { calendarOutline, happyOutline, micCircleOutline, newspaperOutline, videocamOutline } from 'ionicons/icons';
import useEventsBus from "@/composables/eventBus";

const { emit } = useEventsBus();

const scrollToTop = () => {
  emit('scrollToTop');
};

const tabs = [
  {
    tab: 'news',
    href: '/news',
    icon: newspaperOutline,
    label: 'News',
  },
  {
    tab: 'events',
    href: '/events',
    icon: calendarOutline,
    label: 'Events',
  },
  {
    tab: 'comics',
    href: '/comics',
    icon: happyOutline,
    label: 'Comics',
  },
  {
    tab: 'podcasts',
    href: '/podcasts',
    icon: micCircleOutline,
    label: 'Podcasts',
  },
  {
    tab: 'specials',
    href: '/specials',
    icon: videocamOutline,
    label: 'Specials',
  },
];
</script>

<template>
  <ion-tabs>
    <ion-router-outlet></ion-router-outlet>

    <ion-tab-bar slot="bottom">
      <ion-tab-button @click="scrollToTop" :tab="tab.tab" :href="tab.href" v-for="(tab, tabIndex) in tabs" :key="tabIndex">
        <ion-icon :icon="tab.icon" />
        <ion-label>{{tab.label}}</ion-label>
      </ion-tab-button>
    </ion-tab-bar>
  </ion-tabs>
</template>
