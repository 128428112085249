<script setup lang="ts">
import { useGeneralStore } from './stores/general';
import { storeToRefs } from 'pinia';
import Modal from '@/components/Modal.vue';

import GlobalNavigation from './components/GlobalNavigation.vue';

const { globalModalKey, isGlobalModalOpen } = storeToRefs(useGeneralStore());

import {
  IonApp,
  IonRouterOutlet
} from '@ionic/vue';

import { useBackButton, useIonRouter } from "@ionic/vue";
import { App } from '@capacitor/app';

const ionRouter = useIonRouter();
useBackButton(-1, () => {
  if (!ionRouter.canGoBack()) {
    App.exitApp();
  }
});

</script>

<template>
  <ion-app>
    <div class="max-w-[1000px] mx-auto">
      <ion-router-outlet id="main-content"></ion-router-outlet>
    </div>
    
    <global-footer></global-footer>

    <GlobalNavigation />

    <Modal :key="globalModalKey" v-if="isGlobalModalOpen" />
  </ion-app>
</template>

<style scoped>
</style>
