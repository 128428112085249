<script lang="ts" setup>
import { computed, defineEmits, defineProps, onBeforeMount, ref } from 'vue';
import { useStoryblokApi } from "@storyblok/vue";
import { useGeneralStore } from '@/stores/general';
import { storeToRefs } from 'pinia';
import { closeOutline } from 'ionicons/icons';
import { formatDate, resolveHtml } from '@/utils/helper';

import YoutubeEmbed from '@/components/YoutubeEmbed.vue';
import RelatedVideos from '@/components/RelatedVideos.vue';
import RelatedContent from '@/components/RelatedContent.vue';
import RelatedEvents from '@/components/RelatedEvents.vue';
import PodcastEpisodeList from '@/components/PodcastEpisodeList.vue';

import {
  IonButtons,
  IonButton,
  IonModal,
  IonHeader,
  IonContent,
  IonToolbar,
  IonText,
  IonIcon,
  IonAccordion,
  IonAccordionGroup,
  IonItem,
  IonLabel,
  IonSpinner,
  IonPage
} from '@ionic/vue';

const props = defineProps({
  contentSlug: {
    type: String,
    required: false
  }
});

const ytEmbed = ref();

const emit = defineEmits(['closeModal']);

const storyblokApi = useStoryblokApi();
const { activeStory, isGlobalModalOpen } = storeToRefs(useGeneralStore());

const loadingData = ref<boolean>(false);
const storyContent = ref<any>({});
const contentType = ref<string>('');
const relatedVideosRef = ref<any>(null);
const relatedEventsRef = ref<any>(null);

const modalImage = computed(() => {
  if(storyContent.value.content.image && storyContent.value.content.image.filename) {
    return `${storyContent.value.content.image.filename}/m/300x300/smart/filters:format(webp)`;
  } 
  else if(storyContent.value.content.images && storyContent.value.content.images[0] && storyContent.value.content.images[0].filename) {
    return `${storyContent.value.content.images[0].filename}/m/300x300/smart/filters:format(webp)`;
  } 

  else if(storyContent.value.content.logo && storyContent.value.content.logo.filename) {
    return `${storyContent.value.content.logo.filename}`;
  } 
  return '';
});

const modalTitle = computed(() => storyContent.value.content?.title || storyContent.value.content?.name);
const modalDescription = computed(() => typeof storyContent.value.content?.page_content === 'object' ? resolveHtml(storyContent.value.content?.page_content) : storyContent.value.content?.page_content);

const showRelatedVideos = computed(() => relatedVideosRef.value?.hasVideos);
const showRelatedPodcasts = computed(() => storyContent.value.content.related_podcasts && storyContent.value.content.related_podcasts.length > 0);
const showRelatedComics = computed(() => storyContent.value.content.related_comics && storyContent.value.content.related_comics.length > 0);
const showRelatedSpecials = computed(() => storyContent.value.content.related_specials && storyContent.value.content.related_specials.length > 0);
const showRelatedEvents = computed(() => contentType.value === 'comic' || contentType.value === 'venue' && relatedEventsRef.value?.hasEvents);

const cancel = () => {
  if(props.contentSlug) {
    emit('closeModal');
    return;
  }
  
  isGlobalModalOpen.value = false;
  activeStory.value = "";
};

const getActiveContent = async () => {
  loadingData.value = true;
  const slug = props.contentSlug || activeStory.value;

  const resolveRelations = [
    'video.related_comics', 
    'video.related_podcasts', 
    'video.related_comedy_specials', 
    'video.related_cities', 
    'podcast.related_comics', 
    'podcast.related_podcasts', 
    'comic.hometown', 
    'comic.related_comics', 
    'comic.related_podcasts', 
    'comic.related_specials', 
    'venue.related_venues', 
    'venue.related_cities',
    'venue.related_comics',
    'city.related_venues',
    'comedy-special.related_specials', 
    'comedy-special.related_cities',
    'comedy-special.related_comics',
    'comedy-special.related_podcasts'
  ] as string[];

  try {
    const { data } = await storyblokApi.get(`cdn/stories/${slug}`, { version: 'published', resolve_relations: resolveRelations});

    if (data) {
      storyContent.value = data.story;
      contentType.value = data.story.content.component;
    }

  } catch (error) {
    console.log(error);
  }

  loadingData.value = false;
};

const watchInBrowser = () => {
  window.open(storyContent.value.content.watch_link.url, '_blank');
};

onBeforeMount(async () => {
  await getActiveContent();
});

const stopVideoPlayback = () => {
  ytEmbed.value.stopVideoPlayback();
};

</script>

<template>
  <ion-modal :is-open="isGlobalModalOpen" @willDismiss="cancel">
    <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="cancel()">
            <ion-icon :icon="closeOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content v-if="!loadingData">
      <YoutubeEmbed ref="ytEmbed"
        v-if="storyContent.content.video_id" 
        :video-id="storyContent.content.video_id ? storyContent.content.video_id : ''" />

      <img 
        v-else-if="modalImage" 
        :src="modalImage" 
        :alt="modalTitle" 
        class="m-5 w-60 h-60 !rounded-full border-2 border-white mx-auto shadow-lg shadow-pink-600" />
    
      <div class="ion-padding">
        <ion-text>
          <h1 class="mb-2 !text-2xl">{{ modalTitle }}</h1>
        </ion-text>

        <ion-text v-if="storyContent.content.date">
          <p class="font-semibold mb-1 text-sm">{{ formatDate(storyContent.content.date) }}</p>
        </ion-text>

        <ion-text v-else-if="storyContent.content.hometown">
          <p class="text-sm mb-1">Hometown: {{ storyContent.content.hometown.name }}</p>
        </ion-text>

        <ion-text v-if="storyContent.content.watch_link && storyContent.content.watch_link.url">
          <ion-button class="!mt-3" @click="watchInBrowser" size="small" title="Watch this special">Watch Full Special</ion-button>
        </ion-text>
      </div>

      <ion-accordion-group :multiple="true" :value="['first','second','third','fourth','fifth','sixth']">
        <!-- Video Description -->
        <ion-accordion value="first" v-if="storyContent.content.description && !modalDescription">
          <ion-item slot="header">
            <ion-label>More Information</ion-label>
          </ion-item>
          <div class="!p-5 !pt-0" slot="content">
            <ion-text class="text-sm prose text-gray-400" v-html="storyContent.content.description"></ion-text>
          </div>
        </ion-accordion>

        <!-- Page Content -->
        <ion-accordion value="first" v-if="modalDescription">
          <ion-item slot="header">
            <ion-label>About</ion-label>
          </ion-item>
          <div class="!p-5 !pt-0" slot="content">
            <ion-text class="text-sm prose text-gray-400" v-html="modalDescription"></ion-text>
          </div>
        </ion-accordion>

        <!-- Podcast Episodes-->
        <ion-accordion value="second" v-if="contentType === 'podcast' && storyContent.content.channel_id">
          <ion-item slot="header">
            <ion-label>Latest from {{ modalTitle }}</ion-label>
          </ion-item>
          <div class="ion-padding" slot="content">
            <PodcastEpisodeList :channel-id="storyContent.content.channel_id" />
          </div>
        </ion-accordion>

        <!-- Events-->
        <ion-accordion value="second" v-show="showRelatedEvents">
          <ion-item slot="header">
            <ion-label>Upcoming Events</ion-label>
          </ion-item>
          <div slot="content">
            <RelatedEvents ref="relatedEventsRef" :related-slug="modalTitle" />
          </div>
        </ion-accordion>

        <!-- Videos-->
        <ion-accordion value="third" v-show="showRelatedVideos">
          <ion-item slot="header">
            <ion-label>Featured Videos</ion-label>
          </ion-item>
          <div class="ion-padding" slot="content">
            <RelatedVideos 
              ref="relatedVideosRef"
              :content-type="contentType"
              @videoOpened="stopVideoPlayback"
              :item-id="contentType === 'video' ? storyContent.content.related_videos ? storyContent.content.related_videos.toString() : '' : storyContent.uuid" />
          </div>
        </ion-accordion>

        <!-- Comics -->
        <ion-accordion value="fourth" v-if="showRelatedComics">
          <ion-item slot="header">
            <ion-label>Related Comics</ion-label>
          </ion-item>
          <div class="ion-padding" slot="content">
            <RelatedContent content-type="comics" :content="storyContent.content.related_comics" />
          </div>
        </ion-accordion>

        <!-- Podcasts -->
        <ion-accordion value="fifth" v-if="showRelatedPodcasts">
          <ion-item slot="header">
            <ion-label>Related Podcasts</ion-label>
          </ion-item>
          <div class="ion-padding" slot="content">
            <RelatedContent content-type="podcasts" :content="storyContent.content.related_podcasts" />
          </div>
        </ion-accordion>

        <!-- Specials -->
        <ion-accordion value="sixth" v-if="showRelatedSpecials">
          <ion-item slot="header">
            <ion-label>Comedy Specials</ion-label>
          </ion-item>
          <div class="ion-padding" slot="content">
            <RelatedContent content-type="specials" :content="storyContent.content.related_specials" />
          </div>
        </ion-accordion>
      </ion-accordion-group>
    </ion-content>

    <ion-content v-else>
      <ion-spinner name="crescent" />
    </ion-content>
  </ion-page>
  </ion-modal>
</template>
